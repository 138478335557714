import axios from "axios";
import request from "./request";


const getKrogerStoreLocation = async (lat, lng, activeTab = null) => {
  localStorage.setItem('isCalledStore', 'yes');
  const { data } = await request.get(`/kroger/location?lat=${lat}&lng=${lng}&tab=${activeTab}`)
  localStorage.setItem('store', JSON.stringify(data))
  if (activeTab === 'null' || !activeTab || activeTab === 'Ralphs') {
    localStorage.setItem("locationId", JSON.stringify(data.store.locationId));

    // storing the Store Location 
    localStorage.setItem("storeLocation", JSON.stringify(`${data.store.address.addressLine1}, ${data.store.address.city}`));
    localStorage.setItem("storeLat", JSON.stringify(data.store.geolocation.latitude));
    localStorage.setItem("storeLng", JSON.stringify(data.store.geolocation.longitude));

    // returning the location Id of Kroger Shop
    return data.store.locationId;
  } else {
    if (data) {
      localStorage.setItem("storeLocation", JSON.stringify(`${data.store.name}, ${data.store.vicinity}`));
      localStorage.setItem("storeLat", JSON.stringify(data.store.geometry.location.lat));
      localStorage.setItem("storeLng", JSON.stringify(data.store.geometry.location.lng));
    }
  }
}

const makeFirstLetterLowerCase = (str) => {
  if (!str) return str; // Handle empty strings
  return str.charAt(0).toLowerCase() + str.slice(1);
}

const getProductsKroger = async (term, start = 0, limit = 10, activeCompany = null) => {

  const activeCompanyName = makeFirstLetterLowerCase(activeCompany?.name);
  let activeStore;
  if (activeCompanyName) {
    const property = localStorage.getItem("property") && JSON.parse(localStorage.getItem("property"));
    activeStore = property?.stores[activeCompanyName]
  }

  if (!activeCompany || activeCompany === 'null' || activeCompany.name === 'Ralphs') {

    const { data } = await request.get(`/kroger/products?term=${term}&start=${start}&limit=${limit}&locationId=${activeStore.storeId}`);
    data.data.forEach((item) => {
      // if(item.description.toLowerCase().includes("melon") && item.items[0].soldBy === "WEIGHT") {
      //   item.items[0].soldBy = "UNIT";
      //   item.items[0].price.regular = Number((item.items[0].price.regular * 5).toFixed(2));
      // }
      // else 
      if (item.upc === "0000000003094" && item.items[0].soldBy === "WEIGHT") {
        item.items[0].soldBy = "UNIT";
        let price = Number((item.items[0].price.regular * 2.5).toFixed(2));
        item.items[0].price.regular = price;
      }
      else if (item.upc === "0000000004023" && item.items[0].soldBy === "WEIGHT") {
        item.items[0].soldBy = "UNIT";
        let price = Number((item.items[0].price.regular * 2).toFixed(2));
        item.items[0].price.regular = price;
      }
      else if (item.upc === "0000000003907" && item.items[0].soldBy === "WEIGHT") {
        item.items[0].soldBy = "UNIT";
        let price = Number((item.items[0].price.regular * 2).toFixed(2));
        item.items[0].price.regular = price;
      }
      else if (item.upc === "0000000003093" && item.items[0].soldBy === "WEIGHT") {
        item.items[0].soldBy = "UNIT";
        let price = Number((item.items[0].price.regular * 2.5).toFixed(2));
        item.items[0].price.regular = price;
      }
      else if (item.upc === "0000000004022" && item.items[0].soldBy === "WEIGHT") {
        item.items[0].soldBy = "UNIT";
        let price = Number((item.items[0].price.regular * 2).toFixed(2));
        item.items[0].price.regular = price;
      }
      else if (item.upc === "0000000004056" && item.items[0].soldBy === "WEIGHT") {
        item.items[0].soldBy = "UNIT";
        let price = Number((item.items[0].price.regular * 2).toFixed(2));
        item.items[0].price.regular = price;
      }
      else if (item.upc === "0000000004069" && item.items[0].soldBy === "WEIGHT") {
        item.items[0].soldBy = "UNIT";
        let price = Number((item.items[0].price.regular * 2.5).toFixed(2));
        item.items[0].price.regular = price;
      }
      else if (item.upc === "0000000094664" && item.items[0].soldBy === "WEIGHT") {
        item.items[0].soldBy = "UNIT";
        let price = Number((item.items[0].price.regular * 1.34).toFixed(2));
        item.items[0].price.regular = price;
      }
      else if (item.upc === "0000000094629" && item.items[0].soldBy === "WEIGHT") {
        item.items[0].soldBy = "UNIT";
        let price = Number((item.items[0].price.regular * 2).toFixed(2));
        item.items[0].price.regular = price;
      }
      else if (item.upc === "0000000094554" && item.items[0].soldBy === "WEIGHT") {
        item.items[0].soldBy = "UNIT";
        let price = Number((item.items[0].price.regular * 2.5).toFixed(2));
        item.items[0].price.regular = price;
      }
      else if (item.description.toLowerCase().includes("grape") && item.items[0].soldBy === "WEIGHT") {
        item.items[0].soldBy = "UNIT";
        item.items[0].price.regular = Number((item.items[0].price.regular * 2).toFixed(2));
      }
    });

    return data;
  } else if (activeCompany && activeCompany !== 'null') {
    const { data } = await request.get(`/company/products/${activeCompany._id}?start=${start}&limit=${limit}&category=${term}`);
    return data;
  }
}

const getProductDetailsKroger = async (id) => {

  const company = localStorage.getItem('activeCompany');
  const activeCompany = JSON.parse(company);

  if (!activeCompany || activeCompany === 'null' || activeCompany.name === 'Ralphs') {
    const locationId = localStorage.getItem("locationId") && JSON.parse(localStorage.getItem("locationId"));

    const { data } = await request.get(`/kroger/product?locationId=${locationId}&id=${id}`);
    if (data.data.upc === "0000000003094" && data.data.items[0].soldBy === "WEIGHT") {
      data.data.items[0].soldBy = "UNIT";
      let price = Number((data.data.items[0].price.regular * 2.5).toFixed(2));
      data.data.items[0].price.regular = price;
    }
    else if (data.data.upc === "0000000004023" && data.data.items[0].soldBy === "WEIGHT") {
      data.data.items[0].soldBy = "UNIT";
      let price = Number((data.data.items[0].price.regular * 2).toFixed(2));
      data.data.items[0].price.regular = price;
    }
    else if (data.data.upc === "0000000003907" && data.data.items[0].soldBy === "WEIGHT") {
      data.data.items[0].soldBy = "UNIT";
      let price = Number((data.data.items[0].price.regular * 2).toFixed(2));
      data.data.items[0].price.regular = price;
    }
    else if (data.data.upc === "0000000003093" && data.data.items[0].soldBy === "WEIGHT") {
      data.data.items[0].soldBy = "UNIT";
      let price = Number((data.data.items[0].price.regular * 2.5).toFixed(2));
      data.data.items[0].price.regular = price;
    }
    else if (data.data.upc === "0000000004022" && data.data.items[0].soldBy === "WEIGHT") {
      data.data.items[0].soldBy = "UNIT";
      let price = Number((data.data.items[0].price.regular * 2).toFixed(2));
      data.data.items[0].price.regular = price;
    }
    else if (data.data.upc === "0000000004056" && data.data.items[0].soldBy === "WEIGHT") {
      data.data.items[0].soldBy = "UNIT";
      let price = Number((data.data.items[0].price.regular * 2).toFixed(2));
      data.data.items[0].price.regular = price;
    }
    else if (data.data.upc === "0000000004069" && data.data.items[0].soldBy === "WEIGHT") {
      data.data.items[0].soldBy = "UNIT";
      let price = Number((data.data.items[0].price.regular * 2.5).toFixed(2));
      data.data.items[0].price.regular = price;
    }
    else if (data.data.upc === "0000000094664" && data.data.items[0].soldBy === "WEIGHT") {
      data.data.items[0].soldBy = "UNIT";
      let price = Number((data.data.items[0].price.regular * 1.34).toFixed(2));
      data.data.items[0].price.regular = price;
    }
    else if (data.data.upc === "0000000094629" && data.data.items[0].soldBy === "WEIGHT") {
      data.data.items[0].soldBy = "UNIT";
      let price = Number((data.data.items[0].price.regular * 2).toFixed(2));
      data.data.items[0].price.regular = price;
    }
    else if (data.data.upc === "0000000094554" && data.data.items[0].soldBy === "WEIGHT") {
      data.data.items[0].soldBy = "UNIT";
      let price = Number((data.data.items[0].price.regular * 2.5).toFixed(2));
      data.data.items[0].price.regular = price;
    }
    else if (data.data.description.toLowerCase().includes("grape") && data.data.items[0].soldBy === "WEIGHT") {
      data.data.items[0].soldBy = "UNIT";
      data.data.items[0].price.regular = Number((data.data.items[0].price.regular * 2).toFixed(2));
    }
    return data.data;
  } else if (activeCompany && activeCompany !== 'null') {
    const { data } = await request.get(`/company/products-details/${id}`);
    return data;
  }
}

const getGuestProperty = async (pid) => {
  localStorage.removeItem('isCalledStore');
  try {
    const { data } = await request.get(`/properties/guest/${pid}`);
    localStorage.setItem("property", JSON.stringify(data));

    return data._id;
  } catch (err) {
    console.log(err);
  }
}

export { getProductsKroger, getProductDetailsKroger, getGuestProperty, getKrogerStoreLocation }
